import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import logoWhite from "../images/prokure_logo_white_with_text.png";

const FooterSection = ({ title, children }) => (
  <div className="flex flex-col items-center space-y-3">
    <h3 className="text-lg font-semibold text-white">{title}</h3>
    <div className="flex flex-col items-center space-y-2">{children}</div>
  </div>
);

const FooterLink = ({ to, children, external = false }) => {
  const Component = external ? "a" : Link;
  const props = external ? { href: to } : { to };

  return (
    <Component
      {...props}
      className="text-gray-300 transition-colors duration-200 hover:text-white">
      {children}
    </Component>
  );
};

const Footer = () => {
  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? logoWhite;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0d6efd] text-white">
      <div className="container mx-auto px-4 py-8 md:py-8">
        <div className="flex flex-col items-center">
          <Link to="/" className="mb-4">
            <img
              src={logo}
              alt="Company Logo"
              className="h-10 w-auto md:h-12"
            />
          </Link>
          <p className="text-center text-sm text-gray-300 md:text-base">
            Start growing your business today with our powerful B2B e-commerce
            platform.
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 md:py-8">
        <div className="grid grid-cols-3 gap-8 md:gap-12">
          <FooterSection title="Quick Links">
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/features">Features</FooterLink>
            <FooterLink to="/pricing">Pricing</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </FooterSection>

          <FooterSection title="Resources">
            <FooterLink to="/blog">Blog</FooterLink>
            <FooterLink to="/documentation">Documentation</FooterLink>
            <FooterLink to="/support">Support Center</FooterLink>
            <FooterLink to="/faq">FAQ</FooterLink>
          </FooterSection>

          <FooterSection title="Legal">
            <FooterLink to="/privacy">Privacy Policy</FooterLink>
            <FooterLink to="/terms">Terms of Service</FooterLink>
            <FooterLink to="/security">Security</FooterLink>
          </FooterSection>
        </div>
      </div>

      <div className="border-t border-blue-400/30">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0">
            <div className="text-sm text-gray-300">
              © {currentYear} DPI Corporation Private Limited. All rights
              reserved.
            </div>

            <div className="flex items-center space-x-6">
              <FooterLink to="https://twitter.com/company" external>
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
              </FooterLink>
              <FooterLink to="https://linkedin.com/company" external>
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </FooterLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
