import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import Footer from "../../components/Footer";
import SectionHeader from "../../components/SectionHeader";
import baseLogo from "../../images/logo.png";

const FrontPage = () => {
  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? baseLogo;

  return (
    <div className="flex min-h-screen flex-col bg-white">
      <SectionHeader logo={logo} />

      <main className="flex flex-col space-y-8 p-4 md:p-8">
        <section className="mb-8 flex flex-col items-center space-y-4 md:flex-row md:items-start md:space-y-0 md:space-x-4 lg:p-8">
          <div className="flex flex-col space-y-4 p-4 md:order-1 md:w-1/2">
            <h1 className="text-3xl font-medium md:text-4xl">
              Grow with powerful mobile first B2B e-commerce platform.
            </h1>
            <p className="text-base text-gray-600 md:text-lg">
              B2B e-commerce is at an inflection point. By 2020, the size of U.S
              B2B eCommerce market is expected to be twice that of B2C
              eCommerce. Businesses are fast shifting to B2B eCommerce but
              customer expectations are also higher than ever. BharatMart is a
              SaaS platform for running a B2B Business.
            </p>
          </div>
          <div className="relative flex w-full flex-col items-center md:order-2 md:w-1/2">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/c_scale,q_100,w_680/v1540286789/websiteData/illustration_3x.png"
              }
              className="object-fit max-h-80 w-auto"
            />
          </div>
        </section>

        <section className="mb-8 flex flex-col items-center space-y-4 md:flex-row md:items-start md:space-y-0 md:space-x-4 lg:p-8">
          <div className="flex flex-col space-y-4 p-4 md:order-2 md:w-1/2">
            <h1 className="text-3xl font-medium md:text-3xl">Mobile First</h1>
            <p className="text-base text-gray-600 md:text-lg">
              Business buyers are also consumers who have experienced B2C
              ecommerce and they expect the same convenient shopping experience
              in their B2B ecommerce platform.
              <span className="text-brown block">
                Mobile first and an amazon like shopping experience.
              </span>{" "}
            </p>

            <p className="text-base text-gray-600 md:text-lg">
              In BharatMart, you can use ALL the features from your mobile app.
              Built for a mobile first world.
            </p>
            <Link to="/feature-list">
              <button className="w-full rounded-md bg-blue-600 px-4 py-3 text-base text-white transition-colors hover:bg-blue-700 md:w-48">
                Learn More
              </button>
            </Link>
          </div>
          <div className="relative flex w-full flex-col items-center md:order-1 md:w-1/2">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png"
              }
              className="object-fit max-h-80 w-auto"
            />
          </div>
        </section>

        <section className="mb-8 flex flex-col items-center space-y-4 md:flex-row md:items-start md:space-y-0 md:space-x-4 lg:p-8">
          <div className="flex flex-col space-y-4 p-4 md:order-1 md:w-1/2">
            <h1 className="text-3xl font-medium md:text-3xl">
              All the complicated B2B use cases
            </h1>
            <p className="text-base text-gray-600 md:text-lg">
              B2B ecommerce is a lot more complicated than B2C ecommerce.
              <span className="text-green-600">
                Multi-tier distribution selling, geo-fencing, advanced pricing
                rules, fully customizable order and payment flows across all
                layers of supply chain, on the ground logistics and sales
                management etc.
              </span>
              With BharatMart you can manage all the complex B2B business
              requirements effortlessly.
            </p>
            <Link to="/feature-list">
              <button className="w-full rounded-md bg-blue-600 px-4 py-3 text-base text-white transition-colors hover:bg-blue-700 md:w-48">
                Learn More
              </button>
            </Link>
          </div>
          <div className="relative flex w-full flex-col items-center md:order-2 md:w-1/2">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540159281/websiteData/b2b_3x.png"
              }
              className="object-fit max-h-80 w-auto"
            />
          </div>
        </section>

        <section className="mb-8 flex flex-col items-center space-y-4 md:flex-row md:items-start md:space-y-0 md:space-x-4 lg:p-8">
          <div className="flex flex-col space-y-4 p-4 md:order-2 md:w-1/2">
            <h1 className="text-3xl font-medium md:text-3xl">
              Developer First
            </h1>
            <p className="text-base text-gray-600 md:text-lg">
              Commerce is essentially movement of goods from A to B and movement
              of payment in the opposite direction. But between A and B, it can
              take varied forms (marketplace, own inventory, drop shipping etc.
            </p>
            <p className="text-brown text-base md:text-lg">
              In BharatMart, you can use ALL the features from your mobile app.
              Built for a mobile first world.
            </p>
            <Link to="/developer-first">
              <button className="w-full rounded-md bg-blue-600 px-4 py-3 text-base text-white transition-colors hover:bg-blue-700 md:w-48">
                Learn More
              </button>
            </Link>
          </div>
          <div className="relative flex w-full flex-col items-center md:order-1 md:w-1/2">
            <img
              src={
                "https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png"
              }
              className="object-fit max-h-80 w-auto"
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default FrontPage;
