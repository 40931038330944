import React, { useEffect, useState } from "react";
import { Link } from "react-horizontal-router-dom";

const SectionHeader = ({ logo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (isMobileMenuOpen && !event.target.closest("nav")) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === "Escape" && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    return () => document.removeEventListener("keydown", handleEscKey);
  }, [isMobileMenuOpen]);

  return (
    <header className="sticky top-0 z-50 border-b border-gray-100 bg-white/95 backdrop-blur-sm">
      <div className="mx-auto max-w-7xl">
        <nav className="flex items-center justify-between px-4 py-4 md:px-8">
          <div className="flex items-center">
            <Link
              to="/"
              className="relative h-10 w-10 transition-transform duration-300 hover:scale-105 md:h-14 md:w-14">
              <img
                src={logo}
                alt="Company Logo"
                className="h-full w-full rounded-lg object-contain"
              />
            </Link>

            <div className="ml-6 hidden md:block">
              <Link to="/feature-list">
                <button className="group relative overflow-hidden rounded-full border-2 border-purple-600 px-5 py-2.5 text-sm font-medium text-purple-600 transition-all duration-300 hover:bg-purple-600 hover:text-white">
                  <span className="relative z-10">Features</span>
                  <div className="absolute inset-0 origin-left scale-x-0 transform bg-purple-600 transition-transform duration-300 group-hover:scale-x-100"></div>
                </button>
              </Link>
            </div>
          </div>

          <div className="hidden items-center space-x-4 md:flex">
            <Link to="/auth">
              <button className="group relative overflow-hidden rounded-full border-2 border-purple-600 px-6 py-2.5 font-medium text-purple-600 transition-all duration-300 hover:text-white">
                <span className="relative z-10">Login</span>
                <div className="absolute inset-0 origin-left scale-x-0 transform bg-purple-600 transition-transform duration-300 group-hover:scale-x-100"></div>
              </button>
            </Link>
            <Link to="/auth/signup">
              <button className="rounded-full border-2 border-purple-600 bg-purple-600 px-6 py-2.5 font-medium text-white shadow-md transition-colors duration-300 hover:border-purple-700 hover:bg-purple-700 hover:shadow-lg">
                Sign Up
              </button>
            </Link>
          </div>

          <div className="flex items-center space-x-3 md:hidden">
            <Link to="/feature-list">
              <button className="group relative overflow-hidden rounded-full border-2 border-purple-600 px-4 py-2 text-sm font-medium whitespace-nowrap text-purple-600 transition-all duration-300 hover:bg-purple-600 hover:text-white">
                <span className="relative z-10">Features</span>
                <div className="absolute inset-0 origin-left scale-x-0 transform bg-purple-600 transition-transform duration-300 group-hover:scale-x-100"></div>
              </button>
            </Link>

            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="rounded-lg p-2 transition-colors duration-200 hover:bg-gray-100"
              aria-label="Toggle mobile menu"
              aria-expanded={isMobileMenuOpen}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                {isMobileMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </nav>

        <div
          className={`transform px-4 pb-4 transition-all duration-300 ease-in-out md:hidden ${
            isMobileMenuOpen
              ? "translate-y-0 opacity-100"
              : "pointer-events-none hidden -translate-y-4 opacity-0"
          }`}>
          <div className="flex flex-col space-y-3 rounded-lg bg-white p-4 shadow-lg">
            <Link to="/auth">
              <button className="w-full rounded-full border-2 border-purple-600 px-6 py-2.5 font-medium text-purple-600 transition-colors duration-300 hover:bg-purple-600 hover:text-white">
                Login
              </button>
            </Link>
            <Link to="/auth/signup">
              <button className="w-full rounded-full border-2 border-purple-600 bg-purple-600 px-6 py-2.5 font-medium text-white shadow-md transition-colors duration-300 hover:border-purple-700 hover:bg-purple-700">
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SectionHeader;
